import { Stack, Text } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import Form from "./Form";
import { ApiErrorProvider } from "@raiden/library-ui/components/ReactHookForm/ApiError";

function AuthLoginContainer() {
  return (
    <Stack spacing="1rem">
      <Text
        color="gray.700"
        fontWeight="700"
        fontSize="1.5rem"
        textAlign="center">
        <FormattedMessage defaultMessage="Accéder à votre console" />
      </Text>
      <ApiErrorProvider>
        <Form />
      </ApiErrorProvider>
    </Stack>
  );
}

export default AuthLoginContainer;
