import { Button, Text, useToast } from "@chakra-ui/react";
import { apiGetErrors } from "@raiden/library-ui/helpers/api";
import { useConfiguration } from "@raiden/library-ui/hooks/useConfiguration";
import generateApiUri from "@raiden/library-ui/libraries/utils/generateApiUrl";
import { useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import useCountDown from "../../../hooks/useCountDown";
import useRequest from "@raiden/library-ui/hooks/useRequest";

/**
 * @typedef {{
 * challenge: import("./Form").AuthenticatorChallenge,
 * setIsVerificationCodeSent: (isVerificationSent: boolean) => void,
 * }} Props
 */
/**
 * @param {import("react").PropsWithChildren<Props>} props
 */
function ChallengeSms({ challenge, setIsVerificationCodeSent }) {
  const { configuration } = useConfiguration();

  const delayBetweenAttempts =
    configuration?.auth?.two_factor_authentication?.challenges
      ?.delay_between_attempts ?? 0;

  const { start, timeLeft } = useCountDown();

  const { request, isLoading } = useRequest();

  const intl = useIntl();

  const toast = useToast();

  const sendVerificationCode = useCallback(() => {
    request(generateApiUri({ id: "@api.2fa.challenge" }), {
      method: "POST",
      body: {
        // @ts-ignore
        data: {
          challenge_id: challenge.challenge_id,
        },
      },
    })
      .then(() => {
        setIsVerificationCodeSent(true);
        start(delayBetweenAttempts);
      })
      .catch((response) => {
        const error = apiGetErrors({
          error: response,
          detailFallback: intl.formatMessage({
            defaultMessage: "Une erreur est survenue.",
          }),
        });
        const toastId = "2fa-challenge-sms-error";
        if (toast.isActive(toastId)) {
          toast.close(toastId);
        }
        toast({
          id: toastId,
          status: "error",
          title: error._detail,
          duration: null,
          isClosable: true,
        });
      });
  }, [
    challenge.challenge_id,
    delayBetweenAttempts,
    intl,
    request,
    setIsVerificationCodeSent,
    start,
    toast,
  ]);

  return (
    <>
      <Text>
        <FormattedMessage
          defaultMessage="Nous allons vous envoyer un code de vérification par SMS au numéro <b>{phoneNumber}</b>."
          values={{
            phoneNumber: challenge.phone_number,
            b: (chunks) => <b>{chunks}</b>,
          }}
        />
      </Text>

      <Button
        onClick={sendVerificationCode}
        isLoading={isLoading}
        isDisabled={timeLeft > 0}>
        {timeLeft > 0 ? (
          <FormattedMessage
            defaultMessage="Envoyé ({timeLeft}s)"
            values={{ timeLeft }}
          />
        ) : (
          <FormattedMessage defaultMessage="Envoyer" />
        )}
      </Button>
    </>
  );
}

export default ChallengeSms;
