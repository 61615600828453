// helpers
import apiGetErrorFields from "../getErrorFields";
import apiGetErrorDetail from "../getErrorDetail";

/**
 * Retourne la liste des erreurs ainsi que le détail dans un format compréhensible
 *   pour le hook `useForm`.
 * @param {object} param0
 * @param {object} param0.error
 * @param {string} [param0.detailFallback]
 * @returns {object}
 */
export default function apiGetErrors({ error, detailFallback }) {
  return {
    _detail: apiGetErrorDetail({ error }) ?? detailFallback,
    ...apiGetErrorFields({ error }),
  };
}
