// deps
import { useEffect } from "react";
import Head from "next/head";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import Router from "next/router";

// containers
import AuthLogin from "../containers/Auth/Login";

// hooks
import useAuth from "@raiden/library-ui/hooks/useAuth";

// librairies
import generateAdminUrl from "@raiden/library-ui/libraries/utils/generateAdminUrl";

export default function LoginPage(props) {
  const intl = useIntl();

  const { loading, userAdmin } = useAuth();

  useEffect(
    function () {
      if (!loading && Boolean(userAdmin)) {
        let redirect = Router.query?.next?.toString?.();

        if (process.env.NEXT_PUBLIC_ADMIN_BASE_PATH != null && redirect) {
          if (
            !redirect.startsWith(`${process.env.NEXT_PUBLIC_ADMIN_BASE_PATH}/`)
          ) {
            redirect = `${process.env.NEXT_PUBLIC_ADMIN_BASE_PATH}${redirect}`;
          }
        }
        if (redirect) {
          if (!redirect.startsWith("/")) {
            redirect = undefined;
          }
        }
        window.location.href =
          redirect ??
          generateAdminUrl({ id: "dashboard", includeBasePath: true });
      }
    },
    [loading, userAdmin],
  );

  return (
    <>
      <Head>
        <title>
          {intl.formatMessage({
            id: "raiden.admin.pages.Login.head.title",
            defaultMessage: "Accéder à votre console",
          })}
        </title>

        <meta
          name="description"
          content={intl.formatMessage({
            id: "raiden.admin.pages.Login.head.description",
            defaultMessage: "Page de connexion à votre console.",
          })}
        />
      </Head>

      <AuthLogin />
    </>
  );
}

LoginPage.propTypes = {
  cookies: PropTypes.string,
};

LoginPage.getInitialProps = async function () {
  return {
    layout: "signedOut",
  };
};
